import React from "react"
import { Link } from "gatsby"
import { MdKeyboardArrowLeft } from "react-icons/md"

const PreviousArticle = ({ prevArticle }) => {
  const link = prevArticle && `/articles/${prevArticle.node.slug}`
  const title =
    prevArticle && (prevArticle.node.title || prevArticle.node.eventTitle)

  return (
    <Link to={link} className="btn btn-previous">
      <MdKeyboardArrowLeft className="icon" />
      <div className="btn-content">
        <p>ΠΡΟΗΓΟΥΜΕΝΟ</p>
        <h5>{title}</h5>
      </div>
    </Link>
  )
}

export default PreviousArticle
