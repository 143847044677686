import React from "react"

// Icons
import {
  FaShare,
  FaFacebookSquare,
  FaTwitterSquare,
  FaLinkedin,
} from "react-icons/fa"
import { MdEmail } from "react-icons/md"

// Sharing buttons
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from "react-share"

const Share = ({ location }) => {
  return (
    <div className="social-media">
      <hr />

      <p>
        Share this article! <FaShare />
      </p>

      <div className="buttons">
        <FacebookShareButton url={location.href} className="fb-btn btn">
          <FaFacebookSquare />
        </FacebookShareButton>

        <TwitterShareButton url={location.href} className="twitter-btn btn">
          <FaTwitterSquare />
        </TwitterShareButton>

        <LinkedinShareButton url={location.href} className="linked-in-btn btn">
          <FaLinkedin />
        </LinkedinShareButton>

        <EmailShareButton url={location.href} className="email-btn btn">
          <MdEmail />
        </EmailShareButton>
      </div>
    </div>
  )
}

export default Share
