import React from "react"
import PreviousArticle from "./PreviousArticle"
import NextArticle from "./NextArticle"

const ArticleNavigation = ({ pageContext }) => {
  const prevComponent = pageContext.prev ? (
    <PreviousArticle prevArticle={pageContext.prev} />
  ) : (
    <div className="btn-prev" style={{ visibility: "hidden" }}></div>
  )

  const nextComponent = pageContext.next ? (
    <NextArticle nextArticle={pageContext.next} />
  ) : (
    <div className="btn-next" style={{ visibility: "hidden" }}></div>
  )

  return (
    <div className="article-navigation">
      <hr />
      <div className="btn-wrapper">
        {prevComponent}
        {nextComponent}
      </div>
    </div>
  )
}

export default ArticleNavigation
