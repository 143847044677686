import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import { MdArrowBack } from "react-icons/md"

const Banner = ({ title, featuredImage }) => {
  return (
    <BackgroundImage
      tag="div"
      className="article-banner"
      fluid={featuredImage}
      backgroundColor={`#fbb040`}
    >
      <div className="banner-inner">
        <div>
          <Link to="/news-and-events" className="btn-back">
            <MdArrowBack />
            News &amp; Events
          </Link>
        </div>

        <div className="article-details">
          <h1 className="title">{title}</h1>
        </div>
      </div>
      <div className="overlay" />
    </BackgroundImage>
  )
}

export default Banner
