import React from "react";
import { Link } from "gatsby";
import RichTextRenderer from "../utils/richTextRenderer";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import formatDate from "../utils/formatDate";
import { useFeaturedImage } from "../hooks/useFeaturedImage";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Share from "../components/Share";
import Breadcrumb from "../components/Breadcrumb";
import Banner from "../components/Articles/Banner";
import ArticleNavigation from "../components/Articles/ArticleNavigation";

const Article = ({ location, pageContext }) => {
  const { childImageSharp } = useFeaturedImage();

  let originalDate =
    pageContext.article.eventDate || pageContext.article.createdAt;

  let type = pageContext.article.type;
  let title = pageContext.article.title;
  let date = formatDate(originalDate);
  let featuredImage = pageContext.article.featuredImage
    ? pageContext.article.featuredImage.fluid
    : childImageSharp.fluid;
  let SeoImage =
    pageContext.article.featuredImage &&
    pageContext.article.featuredImage.fluid.src;

  return (
    <Layout pageTitle="article">
      <SEO
        pageTitle={pageContext.article.title}
        pageDescription={documentToPlainTextString(pageContext.article.body)}
        pageImage={SeoImage}
      />
      <Breadcrumb isArticle={true} location={title} link={location.pathname} />
      <Banner title={title} type={type} featuredImage={featuredImage} />

      <div className="article-outer">
        <div className="article-inner">
          <div className="sharing">
            <p className="date">{date}</p>

            <Share location={location} />
          </div>
          <div className="content">
            <Link to={type === "event" ? "/events" : `/${type}`}>
              <p className="type">{type}</p>
            </Link>
            <RichTextRenderer richTextJson={pageContext.article.body} />
            <ArticleNavigation pageContext={pageContext} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Article;
