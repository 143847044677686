import React from "react"
import { Link } from "gatsby"
import { MdKeyboardArrowRight } from "react-icons/md"

const NextArticle = ({ nextArticle }) => {
  const link = nextArticle && `/articles/${nextArticle.node.slug}`
  const title =
    nextArticle && (nextArticle.node.title || nextArticle.node.eventTitle)

  return (
    <Link to={link} className="btn btn-next">
      <div className="btn-content">
        <p>ΕΠΟΜΕΝΟ</p>
        <h5>{title}</h5>
      </div>
      <MdKeyboardArrowRight className="icon" />
    </Link>
  )
}

export default NextArticle
